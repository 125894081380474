<template>
  <div class="WebVRToolBar">
  </div>
</template>
<script>
  import uuid from 'uuid'

  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    inject: {
      publicData: {
      },
      getFunc: {}
    },
    data() {
      return {
        krpano: null,
        vrToolBar: null,
      }
    },
    computed: {
      senceGuid() {
        return this.publicData.senceGuid
      },
      pano() {
        for (var i in this.tour.items) {
          var item = this.tour.items[i]
          if (item.guid == this.senceGuid) {
            return item
          }
        }
      },
      tour() {
        return this.publicData.tour
      },
    },
    watch: {
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          add: this.add,
          remove: this.remove,
          onviewchange: this.onviewchange,
          loadCompleted: this.loadCompleted,
        }
      })
      this.krpano = this.getFunc({ target: 'FullPano', name: 'getKrpano' })()
      this.add()
    },
    destroyed() {
      this.remove()
    },
    methods: {
      add() {
        this.vrToolBar = new FollowingMenu(this, this.tour)
        this.vrToolBar.on('itemClick', (fm, guid) => {
          console.log('itemClick', this, guid)
          this.getFunc({ target: 'Data', name: 'toPano' })(guid)
        })
      },
      remove() {
        if (this.vrToolBar) {
          this.vrToolBar.destroy()
          this.vrToolBar = null
        }
      },
      onviewchange() {
        if (this.vrToolBar) {
          this.vrToolBar.onviewchange()
        }
      },
      loadCompleted() {
        console.log('loadCompleted')
        if (this.vrToolBar) {
          this.vrToolBar.updateKrHotspot()
        }
      },
    },
  }
  class FollowingMenu {
    constructor(vm, data) {
      this.guid = uuid.v4()
      this.vm = vm
      this.krpano = vm.krpano
      this.eventName = 'event_' + this.guid
      this.emitEvent = this.getEmitEvent()
      window[this.eventName] = this.emitEvent
      this.krHotspot = {}
      this.update(data)

      this.on('itemLeft', () => {
        if (this.itemPageIndex > 0) {
          this.itemPageIndex--
          this.updateKrHotspot()
        }
      })
      this.on('itemRight', () => {
        console.log('itemRight')
        var items = this.getItems(this.groupId)
        if ((this.itemPageIndex + 1) * this.itemPageSize < items.length) {
          this.itemPageIndex++
          this.updateKrHotspot()
        }
      })
      this.on('groupLeft', () => {
        if (this.groupIndex > 0) {
          this.groupIndex--
          this.groupId = this.groups[this.groupIndex].guid
          this.itemPageIndex = 0
          this.itemPageSize = 3
          this.updateKrHotspot()
        }
      })
      this.on('groupRight', () => {
        console.log('groupRight')
        if ((this.groupIndex + 1) < this.groups.length) {
          this.groupIndex++
          this.groupId = this.groups[this.groupIndex].guid
          this.itemPageIndex = 0
          this.itemPageSize = 3
          this.updateKrHotspot()
        }
      })
      return this;
    }
    update(tour) {
      this.tour = tour
      this.groups = this.getGroups()
      this.groupId = this.vm.pano.groupGUID || (this.groups[0] || {}).guid
      this.groupIndex = 0
      console.log(this.groups)
      for (var i in this.groups) {
        if (this.groups[i].guid == this.groupId) {
          this.groupIndex = i
        }
      }
      this.itemPageIndex = 0
      this.itemPageSize = 3
      this.updateKrHotspot()
    }
    updateKrHotspot(newhs) {
      if (!newhs) {
        newhs = this.getKrHotspot()
      }
      var ohs = this.krHotspot
      for (var i in ohs) {
        if (!newhs[i]) {
          this.krpano.call(`removehotspot(${ohs[i].name},true)`)
        }
      }
      for (var i in newhs) {
        this.addHotspot(newhs[i])
      }
      this.krHotspot = newhs
    }
    getKrHotspot() {
      var items = this.getItems(this.groupId)
      console.log({ items, groupId: this.groupId })
      var ath = this.krpano.get('view.hlookat')
      var atv = 45
      var alpha = 0
      if (this.krHotspot['h' + this.guid + 'bg']) {
        var hbg = this.krpano.get(`hotspot[${(this.krHotspot['h' + this.guid + 'bg'] || {}).name}]`)
        if (hbg) {
          ath = hbg.ath
          atv = hbg.atv
          alpha = hbg.alpha
        }
      }
      console.log({ ath, atv, name: (this.krHotspot['h' + this.guid + 'bg'] || {}).name })
      var scale = 0.15
      var itemW = 50
      var itemM = 10
      var yoffset = 0
      var krHotspot = {}
      var vr_menu_style = {
        depth: 140,
        scale: 0.035,
        distorted: "true",
        ath,
        atv,
        alpha: 0.25,
        torigin: "view",
        // visible: "false",
      }
      var showGroup = this.groups[this.groupIndex]
      krHotspot['h' + this.guid + 'bg'] = {
        name: 'h' + this.guid + 'bg',
        ...vr_menu_style,
        zorder: 7,
        type: "text",
        bgcolor: "0x000000",
        bgalpha: "0.5",
        width: "850",
        height: "350",
        handcursor: "false",
        alpha,
      }
      if (this.groupIndex > 0) {
        krHotspot['h' + this.guid + 'groupLeft'] = {
          name: 'h' + this.guid + 'groupLeft',
          distorted: true,
          zorder: 8,
          capture: false,
          url: `${this.vm.$store.state.settings.filesUrl}${process.env.KrpanoPath || window.global.KRPANO_PATH}/skin/vtourskin118.png`,
          crop: '0|64|33|57',
          width: 15,
          height: 50,
          ath,
          atv,
          ox: -8,
          oy: 0,
          depth: 149,
          scale,
          rotate: 90,

          ty: 0,
          autoalpha: true,
          alpha,

          'onclick': `js(${this.eventName}(groupLeft))`,
        }
      }
      if (this.groupIndex + 1 < this.groups.length) {
        krHotspot['h' + this.guid + 'groupRight'] = {
          name: 'h' + this.guid + 'groupRight',
          distorted: true,
          zorder: 8,
          capture: false,
          url: `${this.vm.$store.state.settings.filesUrl}${process.env.KrpanoPath || window.global.KRPANO_PATH}/skin/vtourskin118.png`,
          crop: '64|64|33|57',
          width: 15,
          height: 50,
          ath,
          atv,
          ox: 8,
          oy: 0,
          depth: 149,
          scale,
          rotate: 90,

          ty: 0,
          autoalpha: true,
          alpha,

          'onclick': `js(${this.eventName}(groupRight))`,
        }
      }

      krHotspot['h' + this.guid + 'showGroupTitle'] = {
        name: 'h' + this.guid + 'showGroupTitle',
        distorted: true,
        zorder: 8,
        enabled: false,
        type: 'text',
        html: showGroup.title,
        css: 'text-align:center;color:#FFFFFF;font-size:14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;',
        oversampling: 3,
        mipmapping: true,
        bgcolor: '0xFFFFFF',
        bgalpha: 0.004,
        width: 240,
        height: 20,
        ath,
        atv,
        ox: ox,
        oy: (yoffset - 70) * scale * 0.5,
        depth: 149,
        scale: scale * 0.8,

        ty: 0,
        autoalpha: true,
        alpha,
      }
      var showItem = this.getArray(items, this.itemPageIndex * this.itemPageSize, this.itemPageSize)
      if (this.itemPageIndex > 0) {
        var d = 0 - ((showItem.length - 1) / 2)
        var ox = (itemW + itemM) * d * scale
        ox = ox - (25 + itemM) * scale
        krHotspot['h' + this.guid + 'itemLeft'] = {
          name: 'h' + this.guid + 'itemLeft',
          distorted: true,
          zorder: 8,
          capture: false,
          url: `${this.vm.$store.state.settings.filesUrl}${process.env.KrpanoPath || window.global.KRPANO_PATH}/skin/vtourskin118.png`,
          crop: '0|64|33|57',
          width: 15,
          height: 50,
          ath,
          atv,
          ox: ox,
          oy: yoffset * scale * 0.5,
          depth: 149,
          scale,

          ty: 0,
          autoalpha: true,
          alpha,

          'onclick': `js(${this.eventName}(itemLeft))`,
        }
      }
      if ((this.itemPageIndex + 1) * this.itemPageSize < items.length) {
        var d = (showItem.length - 1) - ((showItem.length - 1) / 2)
        var ox = (itemW + itemM) * d * scale
        ox = ox + (25 + itemM) * scale
        krHotspot['h' + this.guid + 'itemRight'] = {
          name: 'h' + this.guid + 'itemRight',
          distorted: true,
          zorder: 8,
          capture: false,
          url: `${this.vm.$store.state.settings.filesUrl}${process.env.KrpanoPath || window.global.KRPANO_PATH}/skin/vtourskin118.png`,
          crop: '64|64|33|57',
          width: 15,
          height: 50,
          ath,
          atv,
          ox: ox,
          oy: yoffset * scale * 0.5,
          depth: 149,
          scale,

          ty: 0,
          autoalpha: true,
          alpha,

          'onclick': `js(${this.eventName}(itemRight))`,
        }
      }
      for (var i in showItem) {
        var item = showItem[i]
        var d = i - ((showItem.length - 1) / 2)
        var ox = (itemW + itemM) * d * scale
        console.log({ item, showItem, i })
        krHotspot['h' + item.guid + 'img'] = {
          name: 'h' + item.guid + 'img',
          distorted: true,
          zorder: 8,
          capture: false,
          url: (((item.linkItem || {}).firstImage || { url: '' }).url || '').replace('/0/0/0/0/', '/200/0/0/0/'),
          crop: '50|0|100|100',
          width: 50,
          height: 50,
          ath,
          atv,
          ox: ox,
          oy: yoffset * scale * 0.5,
          depth: 149,
          scale,

          ty: 0,
          autoalpha: true,
          alpha,

          'onclick': `js(${this.eventName}(itemClick,${item.guid}))`,
        }
        krHotspot['h' + item.guid + 'text'] = {
          name: 'h' + item.guid + 'text',
          distorted: true,
          zorder: 9,
          enabled: false,
          type: 'text',
          html: item.title,
          css: 'text-align:center;color:#FFFFFF;font-size:12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;',
          oversampling: 3,
          mipmapping: true,
          bgcolor: '0xFFFFFF',
          bgalpha: 0.004,
          width: 70,
          height: 20,
          ath,
          atv,
          ox: ox,
          oy: (yoffset + 65) * scale * 0.5,
          depth: 149,
          scale: scale * 0.8,

          ty: 0,
          autoalpha: true,
          alpha,

          'onclick': `js(${this.eventName}(itemClick,${item.guid}))`,
        }
      }
      return krHotspot
    }
    getItems(groupId) {
      var is = []
      for (var i in this.tour.items) {
        if (this.tour.items[i].groupGUID == groupId) {
          is.push(this.tour.items[i])
        }
      }
      return is
    }
    getGroups() {
      var gs = []
      for (var i in this.tour.groups) {
        if (this.tour.groups[i].type == 'Pano') {
          gs.push(this.tour.groups[i])
        }
      }
      return gs
    }
    getArray(arr, start, number) {
      var r = []
      for (var i = start; i < start + number && i < arr.length; i++) {
        r.push(arr[i])
      }
      return r
    }
    getEmitEvent() {
      return (name, ...args) => {
        if (this.events && this.events['all']) {
          for (var i in this.events['all']) {
            this.events['all'][i](name, this, ...args)
          }
        }
        if (this.events && this.events[name]) {
          for (var i in this.events[name]) {
            this.events[name][i](this, ...args)
          }
        }
      }
    }
    on(name, func, type) {
      if (!this.events) {
        this.events = {}
      }
      if (type) {
        if (!this.events[`${name},${type}`]) {
          this.events[`${name},${type}`] = []
        }
        if (this.events[`${name},${type}`].indexOf(func) != -1) {
          return
        }
        this.events[`${name},${type}`].push(func)
      } else {
        if (!this.events[name]) {
          this.events[name] = []
        }
        if (this.events[name].indexOf(func) != -1) {
          return
        }
        this.events[name].push(func)
      }
    }
    off(name, func) {
      if (this.events[name]) {
        var i = this.events[name].indexOf(func)
        if (i != -1) {
          this.events[name].splice(index, 1)
        }
      }
    }
    onviewchange() {
      this.getFollowing()
    }
    getFollowing() {
      var hs = []
      for (var i in this.krHotspot) {
        var kh = this.krpano.get(`hotspot[${this.krHotspot[i].name}]`)
        if (kh) {
          hs.push(kh)
        }
      }

      if (hs.length > 0 && hs.every((value) => { return !value.hovering })) {
        var f = 0.01;	// follow speed factor

        var h = this.krpano.get('view.hlookat');
        var v = this.krpano.get('view.vlookat');
        var hsh = hs[0].ath;
        var hsv = hs[0].atv;

        // map to -180 to +180
        h = (h - (h | 0)) + (((h | 0) + 360180) % 360) - 180.0;
        hsh = (hsh - (hsh | 0)) + (((hsh | 0) + 360180) % 360) - 180.0;

        // get the shortest horizontal distance
        var dh = h - hsh;
        dh += (dh > 180) ? -360 : (dh < -180) ? 360 : 0

        // follow slowing horizontally
        hsh += dh * f;

        // fade out when farther away
        var a = Math.abs(v - hsv) / 90.0;
        a = 1 * Math.max(1.0 - 2.0 * Math.sqrt(a), 0);

        // move up the more looking down
        v = v + 55.0 - v * 1.5;
        hsv = hsv * (1 - f) + v * f;

        // set everything
        for (var i in hs) {
          hs[i].alpha = a
          //if (i <= 1) {
          //} else {
          //  if (hs[i].hovering) {
          //    hs[i].alpha = a;
          //  }
          //}
          hs[i].ath = hsh
          hs[i].atv = hsv
        }
      }
    }
    addHotspot(hss) {
      var hotspotSetting = { ...hss }
      if (!this.krpano) return;
      var points = hotspotSetting.points
      if (hotspotSetting.points) {
        delete hotspotSetting.points
      }
      console.log("addHotspot");
      let name = hotspotSetting.name;
      delete hotspotSetting.name;
      if (!this.krpano.get(`hotspot[${name}]`)) {
        this.krpano.call(`addhotspot(${name})`);
      }
      for (const i in hotspotSetting) {
        this.krpano.set(`hotspot[${name}].${i}`, hotspotSetting[i]);
      }
      for (const i in points) {
        for (var j in points[i]) {
          this.krpano.set(`hotspot[${name}].point[${i}].${j}`, points[i][j]);
        }
      }
    }

    destroy() {
      console.log("destroy Hotspot");
      for (var i in this.krHotspot) {
        this.krpano.call(`removehotspot(${this.krHotspot[i].name},true)`)
      }
      clearInterval(this.Animation)
      delete window['event_' + this.guid]
    }
  }
</script>
<style scoped>
</style>
